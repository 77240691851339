import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "partialToUpdate",
    "overlay",
    "loaderOverlay",
    "loader",
    "successPartialToUpdate",
  ];

  connect() {
    this.modalTarget.addEventListener(
      "click",
      this.handleBackdropClick.bind(this)
    );
  }

  disconnect() {
    this.modalTarget.removeEventListener(
      "click",
      this.handleBackdropClick.bind(this)
    );
  }

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;
    this.hideLoader();
  }

  handleSuccess(event) {
    const [_data, _status, xhr] = event.detail;
    this.successPartialToUpdateTarget.innerHTML = xhr.response;
  }

  show() {
    this.modalTarget.classList.remove("hidden");
    if (this.hasPartialToUpdateTarget) {
      this.showLoader();
    }
  }

  close() {
    this.modalTarget.classList.add("hidden");
    if (this.hasPartialToUpdateTarget) {
      this.partialToUpdateTarget.innerHTML = "";
    }
  }

  showLoader() {
    this.loaderOverlayTarget.classList.remove("hidden");
  }

  hideLoader() {
    this.loaderOverlayTarget.classList.add("hidden");
  }

  handleBackdropClick(event) {
    if (event.target === this.overlayTarget) {
      this.close();
    }
  }
}
