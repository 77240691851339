import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["partialToUpdate"]

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail
    this.partialToUpdateTarget.innerHTML = xhr.response
  }
}  
