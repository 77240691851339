import { Controller } from "stimulus"

export default class extends Controller {

  connect() {}

  toggle(event) {
    const targetId = event.target.dataset.value
    var panel = document.getElementById(targetId)
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

}
