import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "button"];

  copy() {
    const text = this.sourceTarget.textContent;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.showFeedback("Copied!");
      })
      .catch((err) => {
        this.showFeedback("Failed!");
        console.error("Could not copy text: ", err);
      });
  }

  showFeedback(message) {
    if (this.hasButtonTarget) {
      const originalText = this.buttonTarget.innerHTML;
      this.buttonTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>${message}`;
      setTimeout(() => {
        this.buttonTarget.innerHTML = originalText;
      }, 2000);
    }
  }
}
