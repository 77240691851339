import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.initializeAutocompleteTags();
    }

    initializeAutocompleteTags() {

        var mainTagListField = document.getElementById('event_tag_list_main');
        var autocompleteTagListField = document.getElementById('event_tag_list_autocomplete');
        var tagDisplay = document.getElementById('tag_display');

        var autocompleteSource = autocompleteTagListField.dataset.autocompleteSource;
        if (typeof autocompleteSource === 'string') {
            autocompleteSource = JSON.parse(autocompleteSource);
        }

        autocomplete(autocompleteTagListField, autocompleteSource);

        document.getElementById('add_tag_button').addEventListener('click', function() {
            var newTag = autocompleteTagListField.value.trim();
            if (newTag) {
                addTag(newTag);
                autocompleteTagListField.value = '';
            }
        });

        function addTag(tag) {
            var currentTags = getCurrentTags();
            if (!currentTags.includes(tag)) {
                currentTags.push(tag);
                mainTagListField.value = currentTags.join(', ');
                renderTags(currentTags);
            }
        }

        function getCurrentTags() {
            return mainTagListField.value.split(',').map(tag => tag.trim()).filter(tag => tag.length > 0);
        }

        function renderTags(tags) {
            tagDisplay.innerHTML = '';
            tags.forEach(tag => {
                var tagHtml = `<div class="flex items-center mr-2 mb-2 bg-gray-200 rounded px-2 py-1 text-sm">
                    <span>${tag}</span>
                    <button type="button" class="ml-1 text-red-500 hover:text-red-700 focus:outline-none" onclick="removeTag('${tag}')">x</button>
                </div>`;
                tagDisplay.insertAdjacentHTML('beforeend', tagHtml);
            });
        }

        window.removeTag = function(tag) {
            var currentTags = getCurrentTags();
            currentTags = currentTags.filter(t => t !== tag);
            mainTagListField.value = currentTags.join(', ');
            renderTags(currentTags);
        }

        var initialTags = getCurrentTags();
        renderTags(initialTags);
    }

}

function autocomplete(input, arr) {
    let currentFocus;
    input.addEventListener('input', function() {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1;
        a = document.createElement('DIV');
        a.setAttribute('id', this.id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        this.parentNode.appendChild(a);
        for (i = 0; i < arr.length; i++) {
            if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                b = document.createElement('DIV');
                b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
                b.innerHTML += arr[i].substr(val.length);
                b.innerHTML += '<input type="hidden" value="' + arr[i] + '">';
                b.addEventListener('click', function() {
                    input.value = this.getElementsByTagName('input')[0].value;
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    input.addEventListener('keydown', function(e) {
        var x = document.getElementById(this.id + 'autocomplete-list');
        if (x) x = x.getElementsByTagName('div');
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode == 38) {
            currentFocus--;
            addActive(x);
        } else if (e.keyCode == 13) {
            e.preventDefault();
            if (currentFocus > -1) {
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        x[currentFocus].classList.add('autocomplete-active');
    }

    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    function closeAllLists(elmnt) {
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != input) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    document.addEventListener('click', function(e) {
        closeAllLists(e.target);
    });
}
