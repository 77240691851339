import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    // Calling Ajax in connect allows page to load first
    this.getClickData();
    this.getClickThroughData();
  }

  getClickData() {
    Rails.ajax({
      url: `/admin/categories/${this.element.dataset.category}/click_data`,
      type: "POST",
      data: "your=data&and=more",
      dataType: "json",
      success: function (response, textStatus, xhr) {
        let clickData = response.data;
        // console.log(clickData);
        for (let key in clickData) {
          // Key is the Entry ID
          // clickData[key] is the hash of data
          // console.log(key + ": " + JSON.stringify(clickData[key]));
          const tdElement = document.querySelector(
            `tr[data-id="${key}"] td.click-data__today`
          );
          tdElement.innerHTML = clickData[key]['today'];
        }
      },
      error: function (xhr, textStatus, error) {
        console.log(xhr, textStatus, error);
      },
    });
  }
  getClickThroughData(){
    Rails.ajax({
      url: `/admin/categories/${this.element.dataset.category}/click_through_data`,
      type: "POST",
      data: "your=data&and=more",
      dataType: "json",
      success: function (response, textStatus, xhr) {
        let clickData = response.data;
        // console.log(clickData);
        for (let key in clickData) {
          // Key is the Entry ID
          // clickData[key] is the hash of data
          // console.log(key + ": " + JSON.stringify(clickData[key]));
          const tdElement = document.querySelector(
            `tr[data-id="${key}"] td.click-through-data__today`
          );
          tdElement.innerHTML = clickData[key]['today']
        }
      },
      error: function (xhr, textStatus, error) {
        console.log(xhr, textStatus, error);
      },
    });
  }
}
