import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'form', 'spinner', 'error'];

  connect() {}

  check(event) {
    const targetId = event.target.parentNode.dataset.value

    const spans = document.querySelectorAll('.rating span')
    for (var i = 0; i < spans.length; ++i) {
      spans[i].classList.remove("checked");
    }

    event.target.parentNode.parentNode.classList.add("checked");
    event.target.parentNode.checked = false
    document.getElementById(targetId).checked = true;
  }

  update(event) {
    const [_data, _status, xhr] = event.detail;
    this.modalTarget.innerHTML = xhr.response;
  }

  spinner(event) {
    this.formTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
  }

  validate(event) {
    const noChecks = document.querySelectorAll("input[type='radio']:checked").length == 0
    if (noChecks) {
      this.errorTarget.classList.remove('hidden')
    }
  }

}
