import { Controller } from "stimulus"

export default class extends Controller {
  connect() {}

  close(event) {
    document.getElementById('promotion').classList.add('hidden')
  }

  rate(event) {
    //set star value in modal
    const targetId = event.target.dataset.value
    document.getElementById(targetId).parentNode.classList.add("checked");
    document.getElementById(targetId).checked = true;

    //open modal
    document.getElementById('ratingsForm').classList.remove('hidden')
    //close promo modal
    document.getElementById('promotion').classList.add('hidden')
  }

}
