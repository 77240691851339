import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "query", "display"];

  connect() {}

  search(event) {
    const query = event.target.value;
    if (query != "") {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        Rails.fire(this.formTarget, "submit");
        this.saveSearchTerm(query);
      }, 750);
    } else {
      return (this.displayTarget.innerHTML = "");
    }
  }

  saveSearchTerm(query) {
    fetch("/user_search_terms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({ user_search_term: { term: query, kind: 0 } }),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error saving search term:", error);
      });
  }

  handleClick(event) {
    const linkName = event.target.textContent.trim();
    Rails.ajax({
      type: "POST",
      url: "/user_search_terms",
      data: `user_search_term[term]=${linkName}&user_search_term[kind]=1`,
      success: (response) => {
        // console.log("Clicked link saved:", response);
        window.location.href = event.target.href;
      },
      error: (error) => {
        console.error("Error saving clicked link:", error);
      },
    });
  }

  result(event) {
    const data = event.detail[0].body.innerHTML;
    if (data.length > 0) {
      return (this.displayTarget.innerHTML = data);
    }
  }

  remove() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      return (this.displayTarget.innerHTML = "");
    }, 350);
  }
}
