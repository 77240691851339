import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["video", "playButton"];

  connect() {
    if (this.isDesktopDevice()) {
      this.showPlayButton();
      this.playButtonTarget.addEventListener("click", () => this.togglePlay());
      this.videoTarget.addEventListener("play", () => this.hidePlayButton());
      this.videoTarget.addEventListener("pause", () => this.showPlayButton());
    } else {
      this.hidePlayButton();
      this.disconnect();
    }
  }

  isDesktopDevice() {
    return !/Mobi|Android/i.test(navigator.userAgent);
  }

  togglePlay() {
    if (this.videoTarget.paused) {
      this.videoTarget.play();
      this.hidePlayButton();
    } else {
      this.videoTarget.pause();
      this.showPlayButton();
    }
  }

  hidePlayButton() {
    this.playButtonTarget.classList.add("hidden");
  }

  showPlayButton() {
    this.playButtonTarget.classList.remove("hidden");
  }
}
