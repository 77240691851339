// app/javascript/controllers/color_picker_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "primaryInput",
    "primaryPreview",
    "secondaryInput",
    "secondaryPreview",
    "svgPrimaryFill",
    "svgSecondaryFill",
    "svgPrimaryStroke",
    "svgSecondaryStroke",
    "svgRaw",
    "rawHtml",
  ];

  connect() {
    this.updatePrimaryPreview();
    this.updateSecondaryPreview();
    this.updateRawHtml();
  }

  updatePrimaryPreview() {
    const color = this.primaryInputTarget.value;
    this.primaryPreviewTarget.style.backgroundColor = color;
    this.svgPrimaryFillTargets.forEach(
      (element) => (element.style.fill = color),
    );
    this.svgPrimaryStrokeTargets.forEach(
      (element) => (element.style.stroke = color),
    );

    this.updateRawHtml();
  }

  updateSecondaryPreview() {
    const color = this.secondaryInputTarget.value;
    this.secondaryPreviewTarget.style.backgroundColor = color;
    this.svgSecondaryFillTargets.forEach(
      (element) => (element.style.fill = color),
    );
    this.svgSecondaryStrokeTargets.forEach(
      (element) => (element.style.stroke = color),
    );

    this.updateRawHtml();
  }

  updateRawHtml() {
    // Clone the SVG element
    const svgClone = this.svgRawTarget.cloneNode(true);

    // Remove all data-color-picker-target attributes from the cloned SVG
    this.removeDataColorPickerTargets(svgClone);

    // Get the outer HTML of the cleaned SVG
    const cleanedSvgHtml = svgClone.outerHTML;

    // Encode the HTML
    const encodedHtml = cleanedSvgHtml
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");

    // Set the encoded HTML to rawHtmlTarget
    this.rawHtmlTarget.innerHTML = encodedHtml;
  }

  removeDataColorPickerTargets(element) {
    // Recursively remove data-color-picker-target attributes
    if (element.hasAttribute("data-color-picker-target")) {
      element.removeAttribute("data-color-picker-target");
    }
    Array.from(element.children).forEach((child) => {
      this.removeDataColorPickerTargets(child);
    });
  }
}
