import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert"];

  connect() {
    this.startAutoClose();
  }

  startAutoClose() {
    this.timeout = setTimeout(() => {
      this.fadeOutAndRemove();
    }, 2000);
  }

  fadeOutAndRemove() {
    this.alertTarget.classList.add(
      "opacity-0",
      "transition-opacity",
      "duration-1000"
    );
    setTimeout(() => {
      this.alertTarget.remove();
    }, 1000);
  }

  close() {
    clearTimeout(this.timeout);
    this.fadeOutAndRemove();
  }

  pauseAutoClose() {
    clearTimeout(this.timeout);
  }

  resumeAutoClose() {
    this.startAutoClose();
  }
}
