import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showModal(event) {
    const [_data, _status, xhr] = event.detail;
    document.querySelector("#modal-content").innerHTML = xhr.responseText;
    this.modalTarget.classList.remove("hidden");

    if (!document.getElementById("overlay-div")) {
      this.setOverlay();
    }

    const closeButton = this.modalTarget.querySelector(
      '[data-action="click->calendar-modal#closeModal"]'
    );

    if (closeButton) {
      closeButton.addEventListener("click", this.closeModal.bind(this));
    }
  }

  closeModal(event) {
    event.preventDefault();
    this.modalTarget.classList.add("hidden");
    var overlay = document.getElementById("overlay-div");
    if (overlay) {
      overlay.remove();
    }
  }

  setOverlay() {
    var overlay = document.createElement("div");
    overlay.className = "bg-gray-900 opacity-50 fixed inset-0 z-40";
    overlay.id = "overlay-div";
    document.body.appendChild(overlay);
  }
}
